
import flatpickr from 'flatpickr';
import { Japanese } from "flatpickr/dist/l10n/ja.js" //日本語用モジュール

const [d] = [document];

const domReady = TPL => false;

const jqInit = () => {
  
  const $input = $('input[type=text][name=text_2]');
  $input.attr('id', 'contact-datepicker');

  //翌週から
  let minDate = new Date();
  minDate = minDate.setDate(minDate.getDate() + 5);

  //2ヶ月後まで
  let maxDate = new Date();
  maxDate = maxDate.setMonth(maxDate.getMonth() + 2);

  flatpickr('#contact-datepicker',{
    locale: Japanese,
    dateFormat: 'Y.m.d（D）',
    minDate: minDate,
    maxDate: maxDate,
    disable: [
      //木曜日以外非表示
      (date) => date.getDay() === 0,
      (date) => date.getDay() === 1,
      (date) => date.getDay() === 2,
      (date) => date.getDay() === 3,
      (date) => date.getDay() === 5,
      (date) => date.getDay() === 6,
      ]
  });

};

export default function CONTACT () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  
  $(() => jqInit());
}

